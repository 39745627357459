pre {
    display: block;
    overflow-x: auto;
    margin: 15px 0;
    padding: 10px;
    color: $black;
    border: 1px solid $gray-100;
    background: #f5f7fb none repeat scroll 0% 0%;

    &.shell {
        color: $gray-100;
        border-color: $gray-900;
        background-color: $gray-800;
    }

    code {
        border: none;
        font-size: 100%;
        line-height: inherit;
        white-space: pre;
        background: none;
        font-size: 95%;
        padding: 0;
    }
}