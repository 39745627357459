// Infraray Colors
$infraray-green: #ffc13e;
$infraray-blue: #222e3c;
$infraray-white: #fff;
$infraray-light: #f4f8fd;
$infraray-text: #000;

// Theme colors
$blue:    #5ca1e5 !default;
$indigo:  #0a0a0a !default;
$purple:  #9559b2 !default;
$pink:    #cc66bb !default;
$brown:   #cc853d !default;
$red:     #e54c2e !default;
$orange:  #fd7e14 !default;
$khaki:   #cccc3d !default;
$yellow:  #ffd400 !default;
$green:   #24b253 !default;
$mint:    #52cc8f !default;
$cyan:    #5ce5cf !default;
$olive:   #99993d !default;

$white:    #fff !default;
$gray-100: #dedfe7 !default;
$gray-200: #bdbdcf !default;
$gray-300: #9ca3b5 !default;
$gray-400: #7b84a1 !default;
$gray-500: #5b6684 !default;
$gray-600: #424d60 !default;
$gray-700: #2c3340 !default;
$gray-800: #191c26 !default;
$gray-900: #0e1013 !default;
$black: #000 !default;


$primary:       $infraray-green !default;
$secondary:     $infraray-blue !default;
$success:       $green !default;
$info:          $blue !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $white !default;
$dark:          $gray-600 !default;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark
) !default;

$notification-colors: (
  // $label, $color, $bg, $border, $link
  ("neutral", #41464b, #e2e3e5, #d3d6d8, #34383c),
  ("danger", #842029, #f8d7da, #f8d7da, #6a1a21),
  ("warning", #976f1a, #fef1d5, #feeac0, #795915),
  ("success", #117054, #d2f1e8, #bbebdd, #0e5a43),
  ("info", #0e616e, #d1ecf1, #b9e3ea, #0b4e58)
  ) !default;

// Social colors
$social-colors: (
  "facebook": #3b5998,
  "twitter": #1da1f2,
  "google": #dc4e41,
  "youtube": #f00,
  "vimeo": #1ab7ea,
  "dribbble": #ea4c89,
  "github": #181717,
  "instagram": #e4405f,
  "pinterest": #bd081c,
  "flickr": #0063dc,
  "bitbucket": #0052cc
) !default;