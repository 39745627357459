.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 0.95rem;
  --bs-alert-padding-y: 0.95rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 0 solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.2rem;
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
  color: var(--bs-alert-color);
  margin-bottom: var(--bs-alert-margin-bottom);
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  position: relative
}

.alert-heading {
  color: inherit
}

.alert-link {
  font-weight: 600
}

.alert-dismissible {
  padding-right: 2.85rem
}

.alert-dismissible .btn-close {
  padding: 1.1875rem .95rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2
}

@each $label, $color, $bg, $border, $link in $notification-colors {
  .alert-#{$label} {
    --bs-alert-color: #{$color};
    --bs-alert-bg: #{$bg};
    --bs-alert-border-color: #{$border};
    
    .alert-link {
      color: #{$link};
    }
  }
}

.alert-icon {
  background: rgba(0, 0, 0, .05);
}

.alert-message {
  box-sizing: border-box;
  width: 100%
}