



@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-color($white);
    @include button-color-hover($white);
  }
}

@each $color, $value in $social-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
    @include button-color($white);
    @include button-color-hover($white);
  }
}

.btn-primary {
  @include button-color-hover(var(--bs-primary));
  --bs-btn-hover-color: var(--bs-primary);
  --bs-btn-hover-bg: transparent;
  --bs-btn-hover-border-color: var(--bs-primary);
  --bs-btn-active-color:  var(--bs-primary);
  --bs-btn-active-bg: transparent;
  --bs-btn-active-border-color: var(--bs-primary);
}

.btn-secondary {
  @include button-color-hover(var(--bs-secondary));
  --bs-btn-hover-color: var(--bs-secondary);
  --bs-btn-hover-bg: transparent;
  --bs-btn-hover-border-color: var(--bs-secondary);
  --bs-btn-active-color:  var(--bs-secondary);
  --bs-btn-active-bg: transparent;
  --bs-btn-active-border-color: var(--bs-secondary);
}

.btn-outline-primary {
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-active-color:  var(--bs-white);
}

.btn-light,
.btn-white {
  @include button-color($gray-800);
  @include button-color-hover($gray-800);
}