// Fonts
$font-family-sans-serif: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif, -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 600 !default;
$font-size-base: .8rem !default;
$font-size-lg: .9rem !default;
$font-size-sm: .65rem !default;
$small-font-size: 80% !default;

// Spacer
$spacer: 1rem !default;

$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3),
  6: ($spacer * 4.5),
  7: ($spacer * 6)
) !default;

// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px
) !default;

// Grid gutter
$grid-gutter-width: 24px;

// Grid containers
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px
) !default;

$container-padding-x: .75rem !default;

// Options
$min-contrast-ratio: 3 !default;
$enable-validation-icons: false !default;
$enable-rfs: false !default;
$enable-negative-margins: true !default;

// Transitions
$transition-base: all .35s ease-in-out !default;



// Body
$body-bg: $infraray-light !default;
$body-color: $infraray-text !default;

// Links
$link-decoration: none !default;
$link-hover-decoration: underline !default;

// Common
$border-width: 1px !default;

// Fonts
$line-height-base: 1.5 !default;
$line-height-lg: 1.5 !default;
$line-height-sm: 1.5 !default;

// Headings
$h1-font-size: $font-size-base * 2 !default;
$h2-font-size: $font-size-base * 1.75 !default;
$h3-font-size: $font-size-base * 1.5 !default;
$h4-font-size: $font-size-base * 1.25 !default;
$h5-font-size: $font-size-base !default;
$h6-font-size: $font-size-base !default;
$headings-color: $infraray-text !default;
$headings-font-weight: 400 !default;

// Displays
$display-font-sizes: (
	1: 6rem,
  2: 5.5rem,
  3: 4.5rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem
  ) !default;

// Shadows
$box-shadow-sm:    0 0.05rem 0.2rem rgba($black, .05) !default;
$box-shadow:       0 0.1rem 0.2rem rgba($black, .05) !default;
$box-shadow-lg:    0 0.2rem 0.2rem rgba($black, .05) !default;

// Navbar input
$navbar-input-bg: darken($body-bg, 0.5%) !default;

// Border radius
$border-radius-sm: .1rem !default;
$border-radius:    .2rem !default;
$border-radius-lg: .3rem !default;

// Navbar
$navbar-padding-y: $spacer*0.875 !default;
$navbar-padding-x: $spacer*1.375 !default;
$navbar-nav-link-padding-x: .5rem !default;
$navbar-border-bottom: 0 !default;
$navbar-box-shadow: 0 0 2rem 0 rgba($dark, .1) !default;

// Navbar brand
$navbar-brand-padding-y: $spacer*0.875 !default;
$navbar-brand-padding-x: 0 !default;
$navbar-brand-color: $gray-100 !default;
$navbar-brand-font-weight: $font-weight-normal !default;
$navbar-brand-font-size: 1.15rem !default;
$navbar-brand-icon-color: $primary !default;

$navbar-bg: $white !default;

// Footer
$footer-bg: $white !default;

// Buttons 

// $btn-border-radius:           0.5rem !default;
// $btn-border-radius-sm:        0.5rem !default;
// $btn-border-radius-lg:        0.5rem !default;

// Forms
$component-active-bg: $primary !default;

$input-bg: $white !default;
$input-disabled-bg: $gray-100 !default;
$input-color: $infraray-text !default;
$input-placeholder-color: $gray-300 !default;

$input-border-color: $gray-300 !default;
$input-focus-border-color: $primary !default;

$input-btn-border-width: $border-width !default;
$input-btn-line-height: $line-height-base !default;
$input-btn-line-height-sm: $line-height-sm !default;
$input-btn-line-height-lg: $line-height-lg !default;
$input-btn-focus-width: .2rem !default;
$input-btn-padding-y: .3rem !default;
$input-btn-padding-y-sm: .2rem !default;
$input-btn-padding-y-lg: .4rem !default;
$input-btn-padding-x: .85rem !default;
$input-border-width: $input-btn-border-width !default;
$input-height-border: $input-border-width * 2 !default;
$input-height-inner: ($font-size-base * $input-btn-line-height) + ($input-btn-padding-y * 2) !default;
$input-height: calc(#{$input-height-inner} + #{$input-height-border}) !default;
$input-height-inner-sm: ($font-size-sm * $input-btn-line-height-sm) + ($input-btn-padding-y-sm * 2) !default;
$input-height-sm: calc(#{$input-height-inner-sm} + #{$input-height-border}) !default;
$input-height-inner-lg: ($font-size-lg * $input-btn-line-height-lg) + ($input-btn-padding-y-lg * 2) !default;
$input-height-lg: calc(#{$input-height-inner-lg} + #{$input-height-border}) !default;

// Cards
$card-border-radius: .25rem !default;
$card-inner-border-radius: $card-border-radius !default;
$card-border-width: 0 !default;
$card-border-color: transparent !default;
$card-cap-bg: $white !default;
$card-shadow: 0 0 .875rem 0 rgba($dark, 0.1) !default;
$card-title-font-size: $font-size-lg !default;
$card-title-font-weight: $font-weight-bold !default;
$card-title-color: lighten($gray-600, 15%) !default;
$card-spacer-y: 1.25rem !default;
$card-spacer-x: 1.25rem !default;
$card-cap-padding-y: 1rem !default;
$card-cap-padding-x: $card-spacer-x !default;

// Tables
$table-cell-padding-y: .75rem !default;
$table-cell-padding-x: .75rem !default;
$table-cell-padding-y-sm: .3rem !default;
$table-cell-padding-x-sm: .3rem !default;
$table-striped-bg: $gray-100 !default;
$table-hover-bg: rgba($black, .0375) !default;

// Sidebar general
$sidebar-width: 260px !default;
$sidebar-transition: margin-left .35s ease-in-out, left .35s ease-in-out, margin-right .35s ease-in-out, right .35s ease-in-out !default;
$sidebar-bg: $secondary !default;

// Sidebar brand
$sidebar-brand-padding-y: 1.15rem !default;
$sidebar-brand-padding-x: 1.5rem !default;
$sidebar-brand-font-weight: $font-weight-bold !default;
$sidebar-brand-font-size: 1.15rem !default;
$sidebar-brand-color: $gray-100 !default;

// Sidebar header
$sidebar-header-padding: 1.5rem 1.5rem 0.375rem !default;
$sidebar-header-font-size: $font-size-sm !default;
$sidebar-header-color: $gray-400 !default;

// Sidebar link
$sidebar-link-padding: .625rem 1.625rem !default;
$sidebar-link-font-weight: 400 !default;
$sidebar-link-bg: $secondary !default;
$sidebar-link-color: rgba($gray-200, 0.5) !default;
$sidebar-link-border-left-color: transparent !default;

$sidebar-link-hover-font-weight: $font-weight-normal !default;
$sidebar-link-hover-bg: $secondary !default;
$sidebar-link-hover-color: #{rgba($white, 0.9)} !default;
$sidebar-link-hover-border-left-color: transparent !default;

$sidebar-link-active-font-weight: $font-weight-normal !default;
$sidebar-link-active-bg: #{linear-gradient(90deg, rgba($primary,0.1) 0%, rgba($primary,0.0875) 50%, rgba($black, 0) 100%)} !default;
$sidebar-link-active-color: $white !default;
$sidebar-link-active-border-left-color: $primary !default;

// Sidebar link icon
$sidebar-link-icon-color: #{rgba($gray-200, 0.5)} !default;
$sidebar-link-icon-hover-color: #{rgba($white, 0.9)} !default;
$sidebar-link-icon-active-color: $white !default;

// Sidebar bottom
$sidebar-cta-color: $gray-100 !default;
$sidebar-cta-border-color: $infraray-text !default;
$sidebar-cta-bg: transparent !default;
$sidebar-cta-padding: $spacer*1.5 !default;
$sidebar-cta-margin: $spacer*1.75 !default;
$sidebar-cta-border-radius: $border-radius-lg !default;

// Transitions
$transition-appearance: background .4s ease-in-out, color .4s ease-in-out !default;
$transition-appearance-slow: background .6s ease-in-out, color .6s ease-in-out !default;
$transition-appearance-fast: background .1s ease-in-out, color .1s ease-in-out !default;

// Modals
$modal-content-border-width: 0 !default;
$modal-header-border-width: 1px !default;
$modal-footer-border-width: 1px !default;
$modal-xl: 1140px !default;
$modal-lg: 900px !default;
$modal-md: 600px !default;
$modal-sm: 400px !default;
$modal-transition: transform .25s ease-out !default;

// Badges
$badge-font-size: 80% !default;
// hack because "padding: $badge-padding-y $badge-padding-x;"
$badge-padding-y: .35em .45em !default;
$badge-padding-x: .3em .45em !default;

// Main
$main-box-shadow: inset .75rem 0px 1.5rem 0px rgba($black, 0.075);

// Content
$content-padding-desktop: 3rem 3rem 1.5rem !default;
$content-padding-mobile: 1.5rem 1.5rem .75rem !default;

// Datetimepicker
$bs-datetimepicker-active-bg: $primary !default;

// Simplebar
$simplebar-scrollbar-bg: $white !default;

// Hamburger
$hamburger-width-top: 24px !default;
$hamburger-width-middle: 24px !default;
$hamburger-width-bottom: 16px !default;

// Stats
$stat-bg: $primary !default;
$stat-icon-color: $white !default;
$stat-padding: $spacer * 0.75 !default;